<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="gfGreenSweetsCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import GreenSweetFig from "../assets/GFGreen/GFGreenSweets/GF_Green_sweets_figs_in_chocolate_front.jpg";
import GreenSweetCoconut from "../assets/GFGreen/GFGreenSweets/GF_Green_sweets_coconut_in_chocolade_front.jpg";
import GreenSweetCoconutCocoa from "../assets/GFGreen/GFGreenSweets/GF_Green_sweets_coconutcoca_in_chocolate_front.jpg";
import GreenSweetMarzipan from "../assets/GFGreen/GFGreenSweets/GF_Green_sweets_marzipan_in_chocolade_front.jpg";
import GreenSweetCashew from "../assets/GFGreen/GFGreenSweets/GF_Green_sweets_fn_with_cashew_front.jpg";
import GreenSweetMacadamia from "../assets/GFGreen/GFGreenSweets/GF_Green_sweets_fn_with_macadamia_front.jpg";
import GreenSweetPecan from "../assets/GFGreen/GFGreenSweets/GF_Green_sweets_fn_with_pecan_front.jpg";
import GreenSweetHazelnut from "../assets/GFGreen/GFGreenSweets/GF_Green_sweets_fn_with_huzelnut_front.jpg";
import GreenSweetDate from "../assets/GFGreen/GFGreenSweets/GF_Green_sweets_date_in_chocolate_front.jpg";

export default {
  name: "GFGreenSweets",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Конфеты",
      gfGreenSweetsCards: [
        {
          picture: GreenSweetFig,
          text: "GOOD FOOD Green конфеты Инжир в шоколаде 150 г",
          id: 1,
          description:
            "Состав: инжир сушеный, шоколадная масса (сахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), сахар, патока крахмальная.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы кунжута, арахиса и орехов.<br>Пищевая ценность в 100 г: Белки - 3,5 г, Жиры 18,4 г, Углеводы 60,9 г.<br>Энергетическая ценность 423 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenSweetCoconut,
          text: "GOOD FOOD Green конфеты Кокосовые в шоколаде 150 г",
          id: 2,
          description:
            "Состав: кокосовая стружка, шоколадная масса (сахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), кокосовое масло рафинированное отбеленное дезодорированное, сахар, патока, молоко сухое обезжиренное, сыворотка сухая молочная, соль пищевая.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы кунжута, арахиса и орехов.<br>Пищевая ценность в 100 г: Белки - 5,8 г, Жиры 29,0 г, Углеводы 46,2 г.<br>Энергетическая ценность 469 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 4 месяца.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenSweetCoconutCocoa,
          text: "GOOD FOOD Green конфеты Кокосовые с какао в шоколаде 150 г",
          id: 3,
          description:
            "Состав: кокосовая стружка, шоколадная масса (cахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), кокосовое масло рафинированное отбеленное дезодорированное, сахар, патока, молоко сухое обезжиренное, сыворотка сухая молочная, какао-порошок, соль пищевая.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы кунжута, арахиса и орехов.<br>Пищевая ценность в 100 г: Белки - 6,9 г, Жиры 30,0 г, Углеводы 47,3 г.<br>Энергетическая ценность 486 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 4 месяца.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenSweetMarzipan,
          text: "GOOD FOOD Green конфеты Марципановые в шоколаде 150 г",
          id: 4,
          description:
            "Состав: марципановая масса (ядра ореха миндаля сладкого, сахар, инвертный сахарный сироп, ядра ореха миндаля горького, этанол),  шоколадная масса (cахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили).<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы кунжута, арахиса и орехов.<br>Пищевая ценность в 100 г: Белки - 14,0 г, Жиры 38,0 г, Углеводы 40,0 г.<br>Энергетическая ценность 558 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenSweetCashew,
          text: "GOOD FOOD Green конфеты орехово-фруктовые с кешью в шоколаде 150 г",
          id: 5,
          description:
            "Состав: финиковая паста (финик сушеный), шоколадная масса (сахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)),  абрикос сушеный, изюм (виноград сушеный без косточки, подсолнечное масло), сушеные ядра орехов кешью и фундука, ядра орехов миндаля жареные.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать фрагменты скорлупы, следы арахиса,  кунжута  и других орехов.<br>Пищевая ценность в 100 г: Белки - 6,2 г, Жиры 31,7 г, Углеводы 46,9 г.<br>Энергетическая ценность 498 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenSweetMacadamia,
          text: "GOOD FOOD Green конфеты орехово-фруктовые с макадамией в шоколаде 150 г",
          id: 6,
          description:
            "Состав: финиковая паста (финик сушеный), шоколадная масса (сахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), банан сушеный, ядра бразильского ореха сушеные, ядра ореха миндаля жареные, ядра ореха макадамии сушеные, семена тыквы сушеные.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать фрагменты скорлупы, следы арахиса,  кунжута  и других орехов.<br>Пищевая ценность в 100 г: Белки - 8,8 г, Жиры 28,9 г, Углеводы 51,3 г.<br>Энергетическая ценность 501 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenSweetPecan,
          text: "GOOD FOOD Green конфеты орехово-фруктовые с пеканом в шоколаде 150 г",
          id: 7,
          description:
            "Состав: финиковая паста (финик сушеный), шоколадная масса (сахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), абрикос сушеный, ядра арахиса жареные, инжир сушеный, сушеные ядра орехов фундука и пекана.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки - 13,1 г, Жиры 30,2 г, Углеводы 44,8 г.<br>Энергетическая ценность 504 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenSweetHazelnut,
          text: "GOOD FOOD Green конфеты орехово-фруктовые с фундуком в шоколаде 150 г",
          id: 8,
          description:
            "Состав: финиковая паста (финик сушеный), шоколадная масса (сахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), ядра арахиса жареные, инжир сушеный, сушеные ядра орехов кешью и фундука, концентрированный сок яблока, семена кунжута.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать фрагменты скорлупы, следы других орехов.<br>Пищевая ценность в 100 г: Белки - 13,2 г, Жиры 30,5 г, Углеводы 47,2 г.<br>Энергетическая ценность 517 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenSweetDate,
          text: "GOOD FOOD Green конфеты Финиковые в шоколаде 150 г",
          id: 9,
          description:
            "Состав: финики сушеные (финики сушеные без косточки, глюкозный сироп), шоколадная масса (сахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), сахар, патока.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать фрагменты скорлупы, следы других орехов.<br>Пищевая ценность в 100 г: Белки - 3,7 г, Жиры 12,8 г, Углеводы 56,0 г.<br>Энергетическая ценность 517 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
